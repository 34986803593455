#headcount-text-style {
  margin-top: -4px;
  margin-bottom: 4px;
  text-indent: 16px;
  color: #5f6b7a;
}

#headcount-text-style-green {
  margin-top: -4px;
  margin-bottom: 4px;
  text-indent: 16px;
  color: limegreen;
}

#headcount-text-style-orange {
  margin-top: -4px;
  margin-bottom: 4px;
  text-indent: 16px;
  color: orangered;
}
