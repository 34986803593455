.ag-theme-quartz {
  font-family: sans-serif;
  font-size: 13px
}

#manager-dashboard-text-style-green {
  color: limegreen;
}

#manager-dashboard-text-style-orange {
  color: orangered;
}
