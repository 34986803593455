.ag-theme-quartz .ag-header-cell-label {
    justify-content: center;
    font-family: sans-serif;
    font-size: 13px;
  }
  
  .ag-theme-quartz .ag-header-group-cell {
    justify-content: center;
    font-family: sans-serif;
    font-size: 13px;
  }
  
  .ag-theme-quartz .ag-cell{
    font-family: sans-serif;
    font-size: 13px
  }