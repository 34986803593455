[data-container="align-items"] {
  display: flex;
  justify-content: stretch;
}

[data-container="allocation-header"] {
  display:flex;
  min-height: 120px;
  align-items: center;
}

[data-container="allocation-table"] {
  min-height: 450px;
}

[data-container="allocation-note"] {
  display: flex;
  flex-direction: column;
}